.portfolio {
  grid-column: full-start / full-end;
  padding: 20rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 7rem;
  background-color: var(--color-dark-black);
}



.porfolioWrapper{
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.portfolioHeader {
  color: var(--color-hilight-brown);
  letter-spacing: 0.3rem;
  font-size: var(--font-size-L);
  font-weight: 500;
  align-self: start;
  justify-self: center;
  align-self: center;
  animation: movingUp .5s ease;
  padding-bottom: 1.5rem;
}

.portfolio__body {
  justify-content: center;
  display: flex;
  gap: 5rem;
  padding: 7rem 0;
}

.portfolioBtn {
  grid-column: 3 / 4;
}

/* //////////////////////////////////////////////////////////////////// */

.cardContent {
  display: grid;
  width: 100%;
}

.card {
  height: 40rem;
  width: 35rem;
  background-color: var(--color-dark-black);
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  color: var(--color-light-cream);
  box-shadow: 1px var(--color-dark-black);
  transition: 0.5s ease;

  .cardImg {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.9;
    filter: saturate(0.7);
  }

  &:hover .cardImg {
    transition: opacity cubic-bezier(0.93, 0.19, 0.44, 0.98) ease-in;

    filter: blur(5px) saturate(0.2) brightness(0.3) opacity(0.35);
    opacity: 1;
  }

  .cardName {
    position: absolute;
    display: grid;
    color: var(--color-light-cream);
    font-size: var(--font-size-big);
    font-weight: 500;
    inset: auto auto 4rem 5rem;
    transition: inset 0.3s ease-out;
  }

  .cardTechIcons {
    /* opacity: 1; */
    transform: scale(0.6);
    display: flex;
    justify-self: center;
  }

  .cardTechIcons,
  .faIcons {
    transition: opacity 0.3s ease-out;
  }

  .cardLink:link,
  .cardLink:visited {
    text-decoration: none;
    color: var(--color-light-cream);
  }

  .cardLink:hover {
    color: var(--color-dark-black);
  }

  .cardLink:active {
    color: var(--color-hilight-brown);
  }

  .faIcons {
    position: absolute;
    fill: var(--color-light-cream);
    color: var(--color-light-cream);
    font-size: var(--font-size-L);
    display: flex;
    gap: 2rem;
    justify-self: center;

    opacity: 0;
    inset: auto auto 4rem auto;
  }

  &:hover .cardName {
    inset: auto auto 6rem 5rem;
    transition: inset 0.2s ease-out;
  }

  &:hover .cardTechIcons {
    justify-self: center;
    opacity: 0;
    transition: 0.01s ease-out;
  }

  &:hover .faIcons {
    opacity: 1;
    transition: opacity 0.1s ease-in;
  }

  &:hover img {
    transition: opacity 0.3s ease-in;
    filter: blur(5px);
    opacity: 1;
  }

  /* Card Empty */

  .cardNameEmpty {
    position: absolute;
    color: var(--color-light-cream);
    font-size: 3rem;
    font-weight: 100;
    display: flex;
    flex-direction: column;
    inset: auto auto 4rem 5rem;
    transition: inset 0.3s ease-out;
    align-items: center;
    justify-content: center;
  }

  &:hover .cardNameEmpty {
    inset: auto auto 6rem 5rem;
    transition: inset 0.2s ease-out;
  }
}

.cardEmpty {
  background-color: var(--color-primary-blueOpaqueplus);
  transition: all 0.5s ease;

  &:hover {
    background-color: var(--color-primary-blueOpaquenext);
  }
}

/* media queries */

@media screen and (max-width: 1280px) {
  .portfolio {
    padding: 12rem 5rem;
  }

  .portfolio__body {
    padding-top: 5rem;
  }

}

@media screen and (max-width: 1024px) {
  .portfolio {
    padding: 10rem 5rem 5rem;
  }

  .portfolio__body {
    padding-top: 5rem;
    padding-bottom: 0;
  }

  .portfolioHeader {
    font-size: 4rem;
  }

  .card{
    .cardNameEmpty {
      font-size: 2.5rem;
    }
  }


}

@media screen and (max-width: 1205px) {

  .portfolio {
    row-gap: 2rem;
  }

  .portfolio__body {
    gap: 2rem;
    padding-bottom: 7rem;
  }

  .card {
    height: 35rem;
    width: 30rem;

    .cardName {
      justify-self: center;
      inset: auto auto 4rem 3rem;
    }
    &:hover .cardName {
      inset: auto auto 6rem 3rem;
    }

    .cardNameEmpty {
      inset: auto 1.5rem 4rem 1rem;
    }
    &:hover .cardNameEmpty {
      inset: auto 1.5rem 6rem 1rem;
    }
  }
}


@media screen and (max-width: 970px) {

  .portfolio__body {
    gap: 5rem;
    flex-direction: column;
  }

  .card {
    height: 40rem;
    width: 35rem;

    .cardName {
      inset: auto auto 4rem 5rem;
    }

    &:hover .cardName {
      inset: auto auto 6rem 5rem;
    }
  }
}

@media screen and (max-width: 890px) {
  .portfolio {
    padding: 10rem 0 5rem;
  }

  .portfolioHeader {
    font-size: 4.5rem;
  }

  .portfolio__body {
    padding-top: 5rem;
  }

}

@media screen and (max-width: 768px) {
  .portfolio {
    padding: 8rem 0 5rem;
  }
}

@media screen and (max-width: 384px) {

  .portfolio__body {
    padding-top: 4rem;
  }

  .card {
    height: 32rem;
    width: 27rem;
    display: grid;
    .cardName {
      justify-self: center;
      inset: auto auto 4rem 1rem;
    }
    &:hover .cardName {
      inset: auto auto 6rem 1rem;
    }
  }
}