.footer {
    display: grid;
    grid-column: full-start / full-end;
    background-color: var(--color-primary-blue);
}

.footerText {
    font-size: var(--font-size-medium);
    color: var(--color-font-primary);
    
}

.footerWrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 30rem;
    align-items: center;
}

@media screen and (max-width: 1700px) {
    .footerWrapper {
        padding: 0 15rem;
    }
  }

@media screen and (max-width: 1400px) {
    .footerWrapper {
        padding: 0 6rem;
    }
  }

  @media screen and (max-width: 750px) {
    .footer {
        height: fit-content;
        padding: 2.5rem 0 1.75rem 0;
    }
    
    .footerWrapper {
        height: 100%;
        flex-direction: column;
        gap: 1.3rem;
        .footerText {
            text-align: center;
        }
        .faWrapper {
            justify-content: center;
        }
    }
  }